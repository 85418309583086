body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --dark-teal: #00b290;
  --light-teal: #b1ffee;
  --light-peach: #ffebcd;
  --med-peach: #ffb8a0;
  --dark-peach: #ff7c4d;
  --abril-font: "Abril Fatface", Georgia, "Times New Roman", Times, serif;
}

body,
html {
  background-color: #b1ffee;
  background-color: var(--light-teal);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

p {
  color: #444;
  line-height: 1.4;
  font-family: "Yantramanav", sans-serif;
  font-size: 1.2rem;
}

h1,
h2,
h3 {
  font-family: "Abril Fatface", Georgia, "Times New Roman", Times, serif;
  font-family: var(--abril-font);
}

a,
a:hover {
  text-decoration: none;
}

a {
  color: #00b290;
  color: var(--dark-teal);
}

a:hover {
  color: #ff7c4d;
  color: var(--dark-peach);
}

.nav {
  -webkit-align-items: center;
          align-items: center;
}

.header-logo {
  margin-left: 20px;
}

/* .category-nav {
  position: sticky;
  top: 60px;
  z-index: 2000;
} */

/* Not logged in */

/* @media screen and (max-width: 489px) {
  .category-nav.logged-out {
    top: 135px;
    padding-top: 11px;
  }
}

@media screen and (min-width: 490px) and (max-width: 667px) {
  .category-nav.logged-out {
    top: 97px;
    padding-top: 11px;
  }
} */

/* Logged in */

/* @media screen and (max-width: 579px) {
  .category-nav.logged-in {
    top: 135px;
    padding-top: 11px;
  }
}

@media screen and (min-width: 580px) and (max-width: 757px) {
  .category-nav.logged-in {
    top: 97px;
    padding-top: 11px;
  }
} */

.top-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2000;
  border-bottom: 1px dotted #ffebcd;
  border-bottom: 1px dotted var(--light-peach);
}

.navbar-nav {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    line-height: 1;
    font-size: 16px;
    width: 180px;
    padding: 10px 0px;
  }
}

.nav-link {
  color: #00b290;
  color: var(--dark-teal);
  font-size: 16px;
  padding: 0.5rem 0rem;
  margin: 0 0.8rem;
}

.category-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.category-button:focus {
  outline: none;
}

.is-active {
  color: #ff7c4d;
  color: var(--dark-peach);
  border-bottom: 1px solid #ff7c4d;
  border-bottom: 1px solid var(--dark-peach);
}

.nav-link:hover {
  color: #ff7c4d;
  color: var(--dark-peach);
}

.bg-light {
  background-color: #fff !important;
}

.bg-dark {
  background-color: #ff7c4d !important;
  background-color: var(--dark-peach) !important;
}

.bg-dark .nav-link {
  color: #fff !important;
  font-weight: 500;
}

.bg-dark .nav-link:hover {
  color: #b1ffee !important;
  color: var(--light-teal) !important;
}

input[type="submit"],
button.btn,
a.btn {
  background-color: #00b290;
  background-color: var(--dark-teal);
  opacity: 1;
  border: 4px solid #333;
  border-radius: 0px;
  text-transform: uppercase;
  font-family: "Abril Fatface", Georgia, "Times New Roman", Times, serif;
  font-family: var(--abril-font);
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  box-shadow: 0px 3px 3px #999;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  color: #ffffff;
}

input[type="submit"]:hover,
button.btn:hover,
a.btn:hover {
  background-color: #00b290;
  background-color: var(--dark-teal);
  border: 4px solid #333;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  box-shadow: 0 0 0 transparent;
}

.link-highlight {
  padding: 2px;
  color: #00b290;
  color: var(--dark-teal);
  box-shadow: inset 0 -3px 0 -1px #ffb8a0;
  box-shadow: inset 0 -3px 0 -1px var(--med-peach);
  -webkit-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
}

.link-highlight:hover {
  color: #00b290;
  color: var(--dark-teal);
  box-shadow: inset 0 -35px 0 -1px #ffb8a0;
  box-shadow: inset 0 -35px 0 -1px var(--med-peach);
}

.yellow {
  height: 500px;
  background-color: yellow;
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: opacity 3s ease, -webkit-transform 3s ease;
  transition: opacity 3s ease, -webkit-transform 3s ease;
  transition: opacity 3s ease, transform 3s ease;
  transition: opacity 3s ease, transform 3s ease, -webkit-transform 3s ease;
}

.container-animate {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.green {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.greeting {
  color: green;
  font-size: 0rem;
  text-align: center;
  -webkit-transition: font-size 3s ease, color 3s ease;
  transition: font-size 3s ease, color 3s ease;
}

.greeting-animate {
  font-size: 3rem;
  color: orange;
}

/*Info labels & Category line in Cards and Profile page */
.info-label {
  font-weight: bolder;
}

.biz-category {
  color: #999;
  font-size: 14px;
}

/*Contact form - recaptcha*/
.g-recaptcha {
  margin-bottom: 20px;
}

